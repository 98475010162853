/**
 * An alternative client for predictions endpoint
 */
import AWSAppSyncClient from 'aws-appsync';
import VueApollo from 'vue-apollo';
import { getGrpahQLToken } from '@/lib/auth';
import { getEnv } from '@/utils/helpers';

const client = new AWSAppSyncClient({
  url: getEnv('DASHBOARD_ENDPOINT_URL') || '',
  region: getEnv('DASHBOARD_APPSYNC_REGION') || '',
  auth: {
    type: 'AMAZON_COGNITO_USER_POOLS',
    jwtToken: async () => getGrpahQLToken(),
  },
  disableOffline: true,
}, {
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

const appSyncProvider = new VueApollo({
  defaultClient: client,
});
const dashboardAppsyncClient = appSyncProvider.defaultClient;

export default dashboardAppsyncClient;
